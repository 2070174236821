import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Close } from "@mui/icons-material";
import { createHeroMedia } from "../../../redux/slices/heroMedia";
import { useDispatch, useSelector } from "../../../redux/store";
import { getCountries } from "src/redux/slices/country";
import Select from "@mui/material/Select";
import { MuiChipsInput } from "mui-chips-input";
import ImageUploading from "react-images-uploading";
import CkEditor from "./CkEditor";

const toastOptions = {
  position: "top-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        borderBottom: "1px solid #e5e8ec",
        width: 600,
        backgroundColor: "#f4f6f8",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreateHeroMediaModal({ sites, open, handleClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [images, setImages] = React.useState([]);
  const { isLoading } = useSelector((state) => state.heroMedia);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const { countries } = useSelector((state) => state.country);

  console.log(countries);

  const handleSubmit = () => {
    const heroMediaData = { ...data };
    dispatch(createHeroMedia(heroMediaData, images, handleClose));
  };

  const handleChangeHeroMediaData = (event) => {
    const heroMediaData = { ...data };
    heroMediaData[event.target.name] = event.target.value;
    setData(heroMediaData);
  };

  const maxNumber = 4;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  return (
    <div>
      <ToastContainer />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {t("Add HeroMedia")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            container
            spacing={3}
          >
            <Grid item lg={12} md={12}>
              <FormControl fullWidth>
                <InputLabel>Language *</InputLabel>
                <Select
                  onChange={handleChangeHeroMediaData}
                  className="form-control"
                  native
                  name="language"
                  defaultValue=""
                  id="grouped-native-select"
                  label="Language "
                  required
                >
                  <option value=""></option>
                 <option value="russian">Russian</option>
                 <option value="english">English</option>
                </Select>
              </FormControl>
            </Grid>
            <br />
            <Grid item lg={12} md={12}>
              <FormControl fullWidth>
                <InputLabel>Type *</InputLabel>
                <Select
                  onChange={handleChangeHeroMediaData}
                  className="form-control"
                  native
                  name="type"
                  defaultValue=""
                  id="grouped-native-select"
                  label="Type"
                  required
                >
                  <option value=""></option>
                  <option value="image">Image</option>
                  {/* <option value="video">Video</option>
                  <option value="file">File</option> */}
                </Select>
              </FormControl>
            </Grid>
            <br />
            <Grid item lg={12} md={12} style={{ marginTop: "10px" }}>
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload-image-wrapper">
                    <LoadingButton
                      onClick={onImageUpload}
                      variant="outlined"
                      style={{
                        borderRadius: "25px",
                        marginRight: "15px",
                        marginBottom: 10,
                      }}
                    >
                      Upload Image
                    </LoadingButton>
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        {/* &nbsp;{' '} */}
                        <img
                          src={image["data_url"]}
                          alt=""
                          width="60px"
                          height="60px"
                        />
                        <div className="image-item-btn-wrapper">
                          <LoadingButton
                            onClick={() => onImageRemove(index)}
                            variant="outlined"
                            style={{
                              borderRadius: "25px",
                              marginRight: "15px",
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            Remove Image
                          </LoadingButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </Grid>
            <Stack
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <LoadingButton
                onClick={() => handleClose()}
                variant="outlined"
                style={{ borderRadius: "25px", marginRight: "15px" }}
              >
                {t("cancel")}
              </LoadingButton>

              <LoadingButton
                style={{ borderRadius: "25px" }}
                // disabled={!data?.heroMediaName}
                onClick={handleSubmit}
                variant="contained"
                loading={isLoading}
              >
                {t("createHeroMedia")}
              </LoadingButton>
            </Stack>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
